<template>
  <div class="login-container">
  	<div class="layer">
  		<div class="some-space">
			<div class="form">
				<h2>上蔬源可视化平台</h2>
				<div class="item">
					<i class="iconfont icon-user"></i>
					<input
						name="userName"
						autocomplete="on"
						type="text"
						class="input"
						v-model="userName"
						placeholder="请输入用户名"
						/>
					<!-- <input
						autocomplete="off"
						type="text"
						class="input"
						v-model="userName"
						placeholder="请输入用户名"
						/> -->
				</div>
				<div class="item">
					<i class="iconfont icon-password"></i>
					<input
					autocomplete="off"
					type="password"
					class="input"
					v-model="userPwd"
					maxlength="20"
					@keyup.enter="login"
					placeholder="请输入密码"
					/>
				</div>
				<button 
					class="loginBtn"
					:disabled="isLoginAble"
					@click.stop="login(0)">
					立即登录
				</button>
				<!-- <div class="tip">
					默认用户名：admin ，默认密码：123456
				</div> -->
			</div>
    	</div>
	</div>

	<!-- <vue-particles 
		name=""
		color="#6495ED"
		:particleOpacity="0.7"
		:particlesNumber="80"
		shapeType="circle"
		:particleSize="4"
		linesColor="#6495ED"
		:linesWidth="1"
		:lineLinked="true"
		:lineOpacity="0.6"
		:linesDistance="150"
		:moveSpeed="3"
		:hoverEffect="true"
		hoverMode="grab"
		:clickEffect="true"
		clickMode="push"
	>
	</vue-particles> -->

    <!-- <bgAnimation /> -->

    <!-- <modal 
      title="提示" 
      :content="modalContent"
      :visible.sync="visible" 
      @confirm="confirm">
    </modal> -->

  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";

export default {
  mixins: [ drawMixin ],
  name: 'Login',
  data() {
  	return {
		userName: '',
		userPwd: '',
		visible: false,
		// modalContent: '这是一段自定义模态框消息'
  	}
  },
  computed: {
  	isLoginAble() {
  		return !(this.userName && this.userPwd);
  	}
  },
  components: {},
  created() {},
  mounted() {
	
	//防止直接修改网址，详情页->登陆页->详情页
	var globalData = {
		companyName: '',
		companyNameShow: false,
		curGroupName: '',
		curGroupId: null,
		curMachine: '',
		curUniqueWebcamSerial: '',
		curWebcamSerial: '',
		curStyleSize: null,  //1：智灵钻， 2：智立方， 3：智立方L， 4：单面屏风， 5：双面屏风， 6：智灵宝， 7：工程模组， 8：集装箱
		curProtocolType: null, //1：家用机协议，2：工程模组协议
		accessToken: null,
		loginToken: null,
		pumpNum: 1,
		curPumpNo: 1,
		curPumpStatus: 0,  //当前水泵状态，0：关闭，1：开启   用于一代机
		pumpList: null,  //水泵列表：包含模组编号，1-10：模组  11-12:主板
		envSensorNum: 1,
		curEnvSensorNo: 1,
		envSensorList: null,  //环境传感器列表：包含模组编号，0：主机， 1-10：模组
		waterSensorNum: 1,
		curWaterSensorNo: 1,
		waterSensorList: null,  //液体传感器列表：包含模组编号，0：主机， 1-10：模组
		navBarShow: 1,
		quitBtnValid: 1,
		webcamShow: 1,
      	machineLoop: true,
		province: '',
		region: '',
		longitude: null,
		latitude: null,
		regionList: null
	}
	this.$store.commit("setGlobalData", globalData);

	// var curUserName = this.$store.state.curUserName;
	// this.$store.state.curUserName = '';
	// this.$store.commit("setCurUserName", '');

	// if(curUserName != undefined && curUserName != null && curUserName != '') {
	// 	this.userName = curUserName;
	// 	this.login(1);
	// }
  },
  methods: {
	
  	login (noPwdLogin) {
		
		if (this.userName == '' && this.userPwd == '') return;
		// if (noPwdLogin != 1 && this.userName == '' && this.userPwd == '') return;
		
		let that = this;
		// var resData = "?groupId=1&machineId=" + this.$GLOBAL.curMachine;
		var resData = "?userName=" + this.userName + "&password=" + this.userPwd + "&noPwdLogin=" + noPwdLogin;
		this.$httpApi.login(resData, function(d) {
			// console.log(JSON.stringify(d));
			if(d.result == true) {
				
				var globalData = {
					companyName: d.data.enterprise,
					companyNameShow: d.data.enterpriseShow,
					curGroupName: d.data.groupName,
					curGroupId: d.data.groupId,
					curMachine: '',
					curOnline: 0,
      				curUniqueWebcamSerial: '',
					curWebcamSerial: '',
					curStyleSize: null,
					curProtocolType: null,
					accessToken: null,
					loginToken: d.data.token,
					pumpNum: 1,
					curPumpNo: 1,
					curPumpStatus: 0,
					pumpList: null,
					envSensorNum: 1,
					curEnvSensorNo: 1,
					envSensorList: null,
					waterSensorNum: 1,
					curWaterSensorNo: 1,
					waterSensorList: null,
					navBarShow: d.data.navBarExists,
              		quitBtnValid: d.data.quitBtnExists,
					webcamShow: d.data.webcamExists,
					webcamType: d.data.webcamType,
					machineLoop: true,
					province: d.data.province,
					region: d.data.region,
					longitude: d.data.longitude,
					latitude: d.data.latitude,
					regionList: d.data.regionList,
				}
				
				that.$store.commit("setGlobalData", globalData);
				that.$store.commit("setCurUserName", d.data.userName);
				
				that.$router.push({
					path: '/home'
				})
			} else {
				that.$Toast({
					content: d.message,
					type: 'error',
					// hasClose: true
				})
			}
			
		}, function(xhr) {
			console.log(xhr.responseText);
		});
		
  	},
    // confirm () {
    //   this.visible = false;
    //   console.log('点击确定')
    // }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
	
	.layer {
		position: absolute;
		height: 100%;
		width: 100%;
		background-image: url("../assets/pageBg.png");
		background-size: cover;
		background-position: center center;
	}
	#particles-js {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
    	z-index: 1000;
	}
	.some-space {
		color: white;
		font-weight: 100;
		letter-spacing: 2px;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1001;
		-webkit-transform: translate3d(-50%, -50%, 0);
		transform: translate3d(-50%, -50%, 0);

		// -ms-animation: cloud 2s 3s ease-in infinite alternate;
		// -moz-animation: cloud 2s 3s ease-in infinite alternate;
		// -webkit-animation: cloud 2s 3s ease-in infinite alternate;
		// -o-animation: cloud 2s 3s ease-in infinite alternate;
		// -webkit-animation: cloud 2s 3s ease-in infinite alternate;
		// animation: cloud 2s 3s ease-in infinite alternate;

	  	.form {
			width: 460px;
			height: auto;
			background: rgba(36, 36, 85, .5);
			margin: 0 auto;
			padding: 35px 30px 25px;
			box-shadow: 0 0 25px rgba(255, 255, 255, .5);
			border-radius: 10px;
	    	.item {
				display: flex;
				align-items: center;
				margin-bottom: 25px;
				border-bottom: 1px solid #d3d7f7;
				i {
					color: #d3d7f7;
					margin-right: 10px;
				}
	    	}
	  		h2 {
				text-align: center;
				font-weight: normal;
				font-size: 26px;
				color: #d3d7f7;
				padding-bottom: 35px;
	  		}
	  		.input {
				font-size: 16px;
				line-height: 30px;
				width: 100%;
				color: #d3d7f7;
				outline: none;
				border: none;
				background-color: rgba(0, 0, 0, 0);
				padding: 10px 0;
	  		}
			input:-webkit-autofill {
				-webkit-text-fill-color: #fff; // 设置填充不变色
				transition: background-color 50000s ease-in-out 0s;
			}
			input:-internal-autofill-previewed,
			input:-internal-autofill-selected {
				-webkit-text-fill-color: #fff;
				transition: background-color 5000s ease-out 0.5s;
			}
			
	  		.loginBtn {
				width: 100%;
				padding: 12px 0;
				border: 1px solid #d3d7f7;
				font-size: 16px;
				color: #d3d7f7;
				cursor: pointer;
				background: transparent;
				border-radius: 4px;
				margin-top: 10px;
    			&:hover {
					color: #fff;
					background: #0090ff;
					border-color: #0090ff;
    			}
	  		}
	  		.tip {
				font-size: 12px;
				padding-top: 20px;
	  		}
	  	}
	  
	}

}

input::-webkit-input-placeholder {
    color: #d3d7f7;
}
input::-moz-placeholder {   /* Mozilla Firefox 19+ */
    color: #d3d7f7;
}
input:-moz-placeholder {    /* Mozilla Firefox 4 to 18 */
    color: #d3d7f7;
}
input:-ms-input-placeholder {  /* Internet Explorer 10-11 */ 
    color: #d3d7f7;
}


@-ms-keyframes cloud{
    0%{
        -ms-transform: translate(-50%, -50%);
    }
    40%{
        opacity: 1;
    }
    60%{
        opacity: 1;
    }
    100%{
        -ms-transform: translate(-50%, -40%);
    }
}
@-moz-keyframes cloud{
    0%{
        -moz-transform: translate(-50%, -50%);
    }
    40%{
        opacity: 1;
    }
    60%{
        opacity: 1;
    }
    100%{
        -moz-transform: translate(-50%, -40%);
    }
}
@-o-keyframes cloud{
    0%{
        -o-transform: translate(-50%, -50%);
    }
    40%{
        opacity: 1;
    }
    60%{
        opacity: 1;
    }
    100%{
        -o-transform: translate(-50%, -40%);
    }
}
@-webkit-keyframes cloud{
    0%{
        -webkit-transform: translate(-50%, -50%);
    }
    40%{
        opacity: 1;
    }
    60%{
        opacity: 1;
    }
    100%{
        -webkit-transform: translate(-50%, -40%);
    }
}
@keyframes cloud{
    0%{
        transform: translate(-50%, -50%);
    }
    40%{
        opacity: 1;
    }
    60%{
        opacity: 1;
    }
    100%{
        transform: translate(-50%, -40%);
    }
}
	
</style>
